exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-das-team-js": () => import("./../../../src/pages/das-team.js" /* webpackChunkName: "component---src-pages-das-team-js" */),
  "component---src-pages-gastronomie-js": () => import("./../../../src/pages/gastronomie.js" /* webpackChunkName: "component---src-pages-gastronomie-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-seminar-raum-js": () => import("./../../../src/pages/seminar-raum.js" /* webpackChunkName: "component---src-pages-seminar-raum-js" */)
}

